<template>
  <div class="main">
    <heads></heads>
    <ctdkbanners></ctdkbanners>

    <div class="cons">
      <div class="qkxq qkxq-wz">
        <div class="qkxq-title">期刊<span>详情</span></div>
        <div class="qkxq-con">
          <div class="qkxq-con-left">
            <div class="qkxq-con-left-img">
              <el-image
                :src="srcurl"
                alt=""
                style="width: 200px; height: 280px"
              />
            </div>
            <div class="qkxq-con-left-xq">
              <div class="qkxq-con-left-xq-title">《{{ obj.Title }}》</div>
              <div
                :class="
                  isqkjj == false
                    ? 'qkxq-con-left-xq-js-ss'
                    : 'qkxq-con-left-xq-js'
                "
              >
                {{ obj.qkjj }}
                <div class="qkxq-con-left-xq-js-ckgd" @click="isqkjj = !isqkjj">
                  {{ isqkjj == false ? "展开" : "收起" }}
                </div>
              </div>
              <div class="qkxq-con-left-xq-jscon">
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>出版周期：</span>{{ obj.cbzq }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>语种：</span>{{ obj.yuzhong }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>影响因子：</span>{{ obj.yxyz }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>国内刊号：</span>{{ obj.gnkh }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>国际刊号：</span>{{ obj.gjkh }}
                </div>
              </div>
              <div class="qkxq-con-left-xq-jscon2">
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.zylm && obj.zylm != ''"
                  :title="obj.zylm"
                >
                  <span>♦ 主要栏目：</span>{{ obj.zylm }}
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.Award && obj.Award != ''"
                  :title="obj.Award"
                >
                  <span>♦ 获奖情况：</span>{{ obj.Award }}
                </div>
                <div class="qkxq-con-left-xq-jscon2-list">
                  <span>♦ 收录情况：</span>
                  <span v-for="(e, i) in obj.shouluqingkuang" :key="i">
                    <a
                      target="_blank"
                      v-if="e.split('：')[1] != ''"
                      style="margin-right: 10px"
                      :href="e.split('：')[1]"
                      >{{ e.split("：")[0] }}</a
                    >
                  </span>
                  <span class="tipsyuyan">
                    <span>&lt;</span>
                    <span>查看更新情况</span>
                  </span>
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.CorePeriodical && obj.CorePeriodical != ''"
                  :title="obj.CorePeriodical"
                >
                  <span>♦ 期刊情况：</span>{{ obj.CorePeriodical }}
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.zgorg && obj.zgorg != ''"
                  :title="obj.zgorg"
                >
                  <span>♦ 主管单位：</span>{{ obj.zgorg }}
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.zborg && obj.zborg != ''"
                  :title="obj.zborg"
                >
                  <span>♦ 主办单位：</span>{{ obj.zborg }}
                </div>
              </div>
            </div>
          </div>
          <div class="qkxq-con-right">
            <div class="qkxq-con-right-lxfs">
              <div class="qkxq-con-right-lxfs-title">联系方式</div>
              <div class="qkxq-con-right-lxfs-con">
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.zhubian && obj.zhubian != ''"
                >
                  <span>主编：</span>{{ obj.zhubian }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.tel && obj.tel != ''"
                >
                  <span>电话：</span>{{ obj.tel }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.yzbm && obj.yzbm != ''"
                >
                  <span>邮编：</span>{{ obj.yzbm }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.dzyx && obj.dzyx != ''"
                >
                  <span>邮箱：</span>{{ obj.dzyx }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.address && obj.address != ''"
                >
                  <span>地址：</span>{{ obj.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="qkxq qkxq-wz qkxq-tg">
        <div class="qkxq-title">投稿<span>详情</span></div>
        <div class="tgxq-con">
          <div class="tgxq-list">
            <div class="tgxq-list-title">标题：</div>
            <div class="tgxq-list-con">{{ listobj.btword }}</div>
          </div>
          <div class="tgxq-list">
            <div class="tgxq-list-title">摘要：</div>
            <div class="tgxq-list-con">{{ listobj.zyword }}</div>
          </div>
          <div class="tgxq-list">
            <div class="tgxq-list-title">关键词：</div>
            <div class="tgxq-list-con">{{ listobj.kwword }}</div>
          </div>
          <div class="tgxq-list" v-if="listobj.writerorg != ''">
            <div class="tgxq-list-title">作者单位：</div>
            <div class="tgxq-list-con">{{ listobj.writerorg }}</div>
          </div>
          <div class="tgxq-list" v-if="listobj.zwword != ''">
            <div class="tgxq-list-title">正文：</div>
            <div class="tgxq-list-con">{{ listobj.zwword }}</div>
          </div>
        </div>
      </div>

      <div class="qksx">
        <div class="qksx-list">
          <div class="qksx-list-title">
            <div class="qksx-list-title-icon"></div>
            <div class="qksx-list-title-wz">投稿分析</div>
          </div>
          <div class="qksx-btn">
            <el-button type="primary" @click="chakangengduo"
              >查看更多</el-button
            >
          </div>
          <div class="qksx-list-con" v-if="!ischuangxinzhishu">
            <div class="cxzs-none">
              当前文章创新指数{{zhiliangfenxi}}，你的文章不适合在本期刊投稿
            </div>
          </div>
          <div class="qksx-list-con" v-if="ischuangxinzhishu">
            <div class="cxzs-display">
              当前文章创新指数{{zhiliangfenxi}}，你的文章适合在本期刊投稿
            </div>
            <div class="qksx-list-con-zhfx" v-if="isjigouyouxian">
              <div class="qksx-list-con-zhfx-title">
                机构优先：<span class="qksx-list-con-zhfx-title-wz">{{
                  parseFloat((1 - indexjigouyouxian / qiyulength).toFixed(2))
                }}</span>
                <!-- <span class="qksx-list-con-zhfx-title-tips">（共{{qiyulength}}本期刊）</span> -->
              </div>
              <div class="qksx-list-con-zhfx-st">
                <div class="qksx-list-con-zhfx-st-list">难</div>
                <div class="qksx-list-con-zhfx-st-list1">
                  <el-progress
                    :show-text="false"
                    :percentage="
                      (1 - (indexjigouyouxian - 1) / qiyulength) * 100
                    "
                    :format="format"
                  />
                </div>
                <div class="qksx-list-con-zhfx-st-list2">易</div>
              </div>
            </div>
            <div class="qksx-list-con-zhfx">
              <div class="qksx-list-con-zhfx-title">
                分类优先：<span class="qksx-list-con-zhfx-title-wz">{{
                  parseFloat((1 - indexfenleiyouxian / qiyulength).toFixed(2))
                }}</span>
                <!-- <span class="qksx-list-con-zhfx-title-tips">（共{{qiyulength}}本期刊）</span> -->
              </div>
              <div class="qksx-list-con-zhfx-st">
                <div class="qksx-list-con-zhfx-st-list">难</div>
                <div class="qksx-list-con-zhfx-st-list1">
                  <el-progress
                    :show-text="false"
                    :percentage="
                      (1 - (indexfenleiyouxian - 1) / qiyulength) * 100
                    "
                    :format="format"
                  />
                </div>
                <div class="qksx-list-con-zhfx-st-list2">易</div>
              </div>
            </div>
            <div class="qksx-list-con-zhfx">
              <div class="qksx-list-con-zhfx-title">
                影响因子：<span class="qksx-list-con-zhfx-title-wz">{{
                  parseFloat((indexyinxiangyinzi / qiyulength).toFixed(2))
                }}</span>
                <!-- <span class="qksx-list-con-zhfx-title-tips">（共{{qiyulength}}本期刊）</span> -->
              </div>
              <div class="qksx-list-con-zhfx-st">
                <div class="qksx-list-con-zhfx-st-list">难</div>
                <div class="qksx-list-con-zhfx-st-list1">
                  <el-progress
                    :show-text="false"
                    :percentage="((indexyinxiangyinzi - 1) / qiyulength) * 100"
                    :format="format"
                  />
                </div>
                <div class="qksx-list-con-zhfx-st-list2">易</div>
              </div>
            </div>
            <div class="qksx-list-con-zhfx">
              <div class="qksx-list-con-zhfx-title">
                投稿指数：<span class="qksx-list-con-zhfx-title-wz">{{
                  parseFloat((1 - indextougaozhishu / qiyulength).toFixed(2))
                }}</span>
                <!-- <span class="qksx-list-con-zhfx-title-tips">（共{{qiyulength}}本期刊）</span> -->
              </div>
              <div class="qksx-list-con-zhfx-st">
                <div class="qksx-list-con-zhfx-st-list">难</div>
                <div class="qksx-list-con-zhfx-st-list1">
                  <el-progress
                    :show-text="false"
                    :percentage="
                      (1 - (indextougaozhishu - 1) / qiyulength) * 100
                    "
                    :format="format"
                  />
                </div>
                <div class="qksx-list-con-zhfx-st-list2">易</div>
              </div>
            </div>
            <div class="qksx-list-con-zhfx" v-if="indexshijianyouxian > 0">
              <div class="qksx-list-con-zhfx-title">
                时间优先：<span class="qksx-list-con-zhfx-title-wz">{{
                  parseFloat(
                    (1 - (indexshijianyouxian-1) / shijianyouxianlength).toFixed(2)
                  )
                }}</span>
                <!-- <span class="qksx-list-con-zhfx-title-tips">（共{{shijianyouxianlength}}本期刊）</span> -->
              </div>
              <div class="qksx-list-con-zhfx-st">
                <div class="qksx-list-con-zhfx-st-list">难</div>
                <div class="qksx-list-con-zhfx-st-list1">
                  <el-progress
                    :show-text="false"
                    :percentage="
                      (1 - (indexshijianyouxian - 1) / shijianyouxianlength) *
                      100
                    "
                    :format="format"
                  />
                </div>
                <div class="qksx-list-con-zhfx-st-list2">易</div>
              </div>
            </div>
            <div class="qksx-list-con-zhfx" v-if="indexchuangxinzhishu>0">
              <div class="qksx-list-con-zhfx-title">
                创新指数：<span class="qksx-list-con-zhfx-title-wz">{{parseFloat(indexchuangxinzhishu.toFixed(2))}}</span>
              </div>
              <div class="qksx-list-con-zhfx-st">
                <div class="qksx-list-con-zhfx-st-list">难</div>
                <div class="qksx-list-con-zhfx-st-list1">
                  <el-progress
                    :show-text="false"
                    :percentage="indexchuangxinzhishu*100"
                    :format="format"
                  />
                </div>
                <div class="qksx-list-con-zhfx-st-list2">易</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, inject, onMounted, getCurrentInstance } from "vue";
import "echarts-wordcloud";
import { get, post } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 保存期刊列表
let qkarr = reactive([]);
// 期刊简介展开
let isqkjj = ref(false);
// 词云
let $echarts = inject("echarts");
// 返回的数据
let obj = reactive({});
let listobj = reactive({});
// 图片路径
let srcurl = ref("");
// 创新指数
let zhiliangfenxi = ref(0);
let ischuangxinzhishu = ref(false);
// 综合分析
const qikanId = router.currentRoute.value.query.id;
let indexfenleiyouxian = ref(0);
let indexyinxiangyinzi = ref(0);
let indextougaozhishu = ref(0);
let indexjigouyouxian = ref(0);
let indexchuangxinzhishu = ref(0);
let isjigouyouxian = ref(false); //是否显示机构优先
let qiyulength = ref(0); //其他期刊总数
let indexshijianyouxian = ref(0);
let shijianyouxianlength = ref(0); //时间优先总期刊数

// echarts 基金占比
// let statejjzb = reactive({
//   option: {
//     grid: {
//       top: "10%",
//       left: "5%",
//       right: "8%",
//       bottom: "20%",
//     },
//     xAxis: {
//       name: "创新指数区间",
//       type: "category",
//       // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//       data: [],
//       axisLabel: {
//         interval: 0,
//         rotate: -45, //倾斜的程度
//       },
//     },
//     yAxis: {
//       name: "占比",
//       type: "value",
//       axisLine: {
//         show: true, // 是否显示坐标轴轴线
//       },
//     },
//     tooltip: {
//       trigger: "axis", //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
//       axisPointer: {
//         // 坐标轴指示器，坐标轴触发有效
//         type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
//       },
//     },
//     series: [
//       {
//         data: [],
//         // data: [
//         //   120,
//         //   {
//         //     value: 200,
//         //     itemStyle: {
//         //       color: "#a90000",
//         //     },
//         //   },
//         //   150,
//         //   80,
//         //   70,
//         //   110,
//         //   130,
//         // ],
//         type: "bar",
//         label: {
//           show: true, //开启显示
//           position: "top", //在上方显示
//           // formatter: function (name) {
//           //   if(name.value>0){
//           //     return (name.value*100).toFixed(1)+'%';
//           //   }else{
//           //     return 0
//           //   }

//           // },
//           // textStyle: {
//           //   //数值样式
//           //   color: "black", //字体颜色
//           //   fontSize: 10, //字体大小
//           // },
//         },
//       },
//     ],
//   },
// });

// let statejjzbcopy = reactive({
//   option: {
//     tooltip: {
//       formatter: "{a} <br/>{b} : {c}",
//     },
//     series: [
//       {
//         progress: {
//           show: true,
//         },
//         detail: {
//           valueAnimation: true,
//           formatter: "{value}",
//         },
//         min: 0,
//         max: 1,
//         name: "创新指数",
//         type: "gauge",
//         center: ["50%", "55%"],
//         radius: "100%",
//         detail: {
//           formatter: "{value}",
//         },
//         data: [
//           {
//             value: 0,
//             name: "你的文章",
//           },
//         ],
//       },
//     ],
//   },
// });

// 进度条显示格式
const format = (percentage) => ``;

// 获取期刊列表
const getqikaninfo = () => {
  get("/journalRecommend/GetBynid", {
    id: router.currentRoute.value.query.tjid,
  }).then((res) => {
    qkarr.push(...JSON.parse(res.data.xgqk).qklist);
    qiyulength.value = qkarr.length;
    // 生成投稿指数
    qkarr.forEach((e) => {
      if (parseFloat(e.kz12) == 0) {
        e.tuijianzhishu = parseFloat(
          Math.log(
            parseFloat((zhiliangfenxi.value / 0.01).toFixed(2)) + 1.01
          ).toFixed(2)
        );
      } else {
        e.tuijianzhishu = parseFloat(
          Math.log(
            parseFloat((zhiliangfenxi.value / parseFloat(e.kz12)).toFixed(2)) +
              1.01
          ).toFixed(2)
        );
      }
    });

    // 是否在提交的时候填写了机构istxjg，填写了需要生成机构指数
    if (res.data.writerorg && res.data.writerorg != "") {
      // 如果存在机构，调用曾的接口加权重，加默认排序字段上fhjsvalue
      post("/journalOrganKeywords/getJournalByKeywords", {
        organ: res.data.writerorg,
        limit: 200,
      }).then((result) => {
        // 没有找到期刊fhjsvaluejigou==fhjsvalue
        if (result.result.length == 0) {
          qkarr.forEach((e) => {
            e.fhjsvaluejigou = e.fhjsvalue;
          });
        } else {
          qkarr.forEach((e) => {
            // 找到相同的  (e.fhjsvalue + ele.totalTermFreq * 3) / 2;
            try {
              result.result.forEach((ele) => {
                if (e.Id == ele.journalId) {
                  e.fhjsvaluejigou = (e.fhjsvalue + ele.totalTermFreq * 3) / 2;
                  throw Error();
                } else {
                  e.fhjsvaluejigou = e.fhjsvalue;
                }
              });
            } catch (error) {}
          });
        }

        // 机构优先
        isjigouyouxian.value = true;
        let qkarrtemp4 = qkarr;
        sortDownByKey(qkarrtemp4, "fhjsvaluejigou").forEach((e, i) => {
          if (e.Id == qikanId) {
            indexjigouyouxian.value = i + 1;
          }
        });
      });
    }

    // 生成综合分析 分类优先
    let qkarrtemp1 = qkarr;
    sortDownByKey(qkarrtemp1, "xtcynumdouble").forEach((e, i) => {
      if (e.Id == qikanId) {
        indexfenleiyouxian.value = i + 1;
      }
    });
    // 影响因子
    let qkarrtemp2 = qkarr;
    sortDownByKey(qkarrtemp2, "yxyz").forEach((e, i) => {
      if (e.Id == qikanId) {
        indexyinxiangyinzi.value = i + 1;
      }
    });
    // 投稿指数
    let qkarrtemp3 = qkarr;
    sortDownByKey(qkarrtemp3, "tuijianzhishu").forEach((e, i) => {
      if (e.Id == qikanId) {
        indextougaozhishu.value = i + 1;
      }
    });

    // 时间优先
    let qkarrtemp5 = qkarr.filter((e) => {
      return e.qkggtype > 1;
    });
    shijianyouxianlength.value = qkarrtemp5.length;
    let qkarrtemp6 = qkarrtemp5.sort((a, b) => {
      if (b.qkggtype !== a.qkggtype) {
        return b.qkggtype - a.qkggtype;
      } else {
        return new Date(b.yxtj) - new Date(a.yxtj);
      }
    });
    qkarrtemp6.forEach((e, i) => {
      if (e.Id == qikanId) {
        indexshijianyouxian.value = i + 1;
      }
    });
  });
};
getqikaninfo();

// 获取数据
function getList() {
  get("/Journal/GetQkInfo", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    Object.assign(obj, res.data);
    obj.shouluqingkuang = obj.slqkdet.split(";\n");
    srcurl.value = `${proxy.$imgurl}/qikanfmpic/${obj.pycode}.jpg`;

    // 页面标题描述摘要
    document.title = res.data.seotitle;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", res.data.seokeyword);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", res.data.kz11);
  });
}

// 获取列表
const getlistqk = () => {
  get("/journalRecommend/GetBynidOnlyCoreperiodical", {
    id: router.currentRoute.value.query.tjid,
  }).then((res) => {
    // console.log(res);
    Object.assign(listobj, res.data);
    let resdata = JSON.parse(res.data.xgqk);
    let reslist = resdata.filter((e) => {
      return e.id == router.currentRoute.value.query.id;
    });
    if (reslist.length > 0) {
      ischuangxinzhishu.value = true;
      // 创新指数
      let pukannum = resdata.filter((e) => {
        return e.coreperiodical == "普刊";
      }).length;
      // 全部刊数量
      let allkannum = 0;
      resdata.forEach((e) => {
        allkannum += e.coreperiodical.split(",").length;
      });
      zhiliangfenxi.value = Math.abs(Math.log(pukannum / allkannum)).toFixed(2);
      // 获取最大值和最小值
      get("/journalRecommend/GetQkWordCxzsqj", {
        id: router.currentRoute.value.query.id,
      }).then((result) => {
        // console.log(result)
        if (result.data === null) {
          indexchuangxinzhishu.value=0
        } else {
          // 创新指数 0-1
          result.data.forEach((e, i) => {
            e.min = parseFloat(e.str.split("-")[0]);
            e.max = parseFloat(e.str.split("-")[1]);
            if (zhiliangfenxi.value >= e.min) {
              indexchuangxinzhishu.value += e.value;
            }
          });
        }
      });
    } else {
      ischuangxinzhishu.value = false;
      // 创新指数
      let pukannum = resdata.filter((e) => {
        return e.coreperiodical == "普刊";
      }).length;
      // 全部刊数量
      let allkannum = 0;
      resdata.forEach((e) => {
        allkannum += e.coreperiodical.split(",").length;
      });
      zhiliangfenxi.value = Math.abs(Math.log(pukannum / allkannum)).toFixed(2);
    }
  });
};

// 查看更多
const chakangengduo = () => {
  router.push({
    path: "/xkzs/res",
    query: {
      id: router.currentRoute.value.query.tjid,
    },
  });
};

// 创新评估
// const initeCharjjzb = () => {
//   if (document.getElementById("tgpgechaqrts")) {
//     let myChart = proxy.$echarts.init(document.getElementById("tgpgechaqrts"));
//     myChart.setOption(statejjzb.option);
//   }
// };

//数组对象方法排序:降序
const sortDownByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });
};

onMounted(() => {
  getList();
  getlistqk();
});
</script>

<style scoped>
:deep(.el-image) {
  width: 100%;
  height: 100%;
}
:deep(.el-image__inner) {
  width: 100%;
  height: 100%;
}
:deep(.el-tabs--border-card > .el-tabs__content) {
  padding: 20px;
}
.main {
  background: #f4f4f4;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 50px 0;
}
.qkxq {
  background: #fff;
}
.qkxq-wz {
  border: 1px solid #dcdfe6;
}
.qkxq-title {
  width: 100%;
  height: 60px;
  border-left: 2px solid #3860f3;
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 60px;
  color: #3860f3;
  font-weight: bold;
  font-size: 24px;
}
.qkxq-title > span {
  color: #666;
}
.qkxq-con {
  position: relative;
}
.qkxq-con::after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left {
  width: 910px;
  height: auto;
  float: left;
  padding: 20px;
  box-sizing: border-box;
}
.qkxq-con-left:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-img {
  width: 200px;
  height: 280px;
  float: left;
  box-sizing: border-box;
  border: 1px solid #eee;
}
.qkxq-con-left-xq {
  width: 670px;
  height: auto;
  float: left;
  padding: 0 20px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  font-size: 14px;
}
.qkxq-con-left-xq-title {
  font-size: 20px;
  font-weight: bold;
}
.qkxq-con-left-xq-js {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: justify;
  margin-bottom: 20px;
  color: #888;
  line-height: 22px;
}
.qkxq-con-left-xq-js-ss {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 30px;
  padding-right: 40px;
  color: #888;
  line-height: 22px;
  overflow: hidden;
}
.qkxq-con-left-xq-js-ckgd {
  position: absolute;
  right: 0;
  bottom: -4px;
  color: #3860f3;
  cursor: pointer;
}
.qkxq-con-left-xq-jscon {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.qkxq-con-left-xq-jscon:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-xq-jscon-list {
  float: left;
  width: 33.33%;
  display: flex;
  align-items: center;
  color: #3860f3;
  margin-bottom: 5px;
}
.qkxq-con-left-xq-jscon-list > span {
  width: 90px;
  display: block;
}
.qkxq-con-left-xq-jscon2:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-xq-jscon2-list {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qkxq-con-left-xq-jscon2-list > span {
  width: 100px;
  color: #888;
  display: inline;
}
.qkxq-con-left-xq-jscon2-list-50 {
  width: 50%;
}
.qkxq-con-right {
  width: 287px;
  float: left;
  padding: 20px 20px 20px 0;
  box-sizing: border-box;
}
.qkxq-con-right-img {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.qkxq-con-right-lxfs {
  width: 100%;
}
.qkxq-con-right-lxfs-con {
  font-size: 14px;
  padding-top: 10px;
}
.qkxq-con-right-lxfs-con:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-right-lxfs-list {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.qkxq-con-right-lxfs-title {
  font-size: 18px;
  font-weight: bold;
  color: #888;
}

.hzhishushuom {
  position: absolute;
  bottom: 60px;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.zhishu {
  margin-top: 30px;
}
.zhishu-list {
  float: left;
  width: 100%;
  height: 600px;
}
.fenlei {
  margin-top: 20px;
}
.fenlei-title {
  width: 100%;
  height: 40px;
  background: #e2e2e2;
}
.fenlei-titles {
  width: 100px;
  height: 40px;
  float: left;
  text-align: center;
  line-height: 40px;
  color: #333;
  cursor: pointer;
}
.fenlei-wzlist:after {
  content: "";
  display: table;
  clear: both;
}
.fenlei-wzlists {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
  background: url("/img/detail/xiaoyuandian.jpg");
}
.fenlei-wzlists-title,
.fenlei-wzlists-writer {
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}
.fenlei-wzlists-title:hover {
  color: #3860f3;
}
.fenlei-titles-avtive {
  background: #393c41;
  color: #fff;
}
.tgxq {
  font-size: 14px;
}
.hzs-null {
  font-size: 14px;
}
.xsfx,
#echartsyyy {
  width: 1160px;
  height: 600px;
}

.qksx {
  width: 100%;
  height: auto;
  background: #fff;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #dcdfe6;
}
.qksx-list {
  position: relative;
}
.qksx-list-title {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}
.qksx-list-title-icon {
  width: 5px;
  height: 20px;
  background: #3860f3;
  margin-right: 10px;
}
.qksx-list-con {
  font-size: 14px;
  padding: 20px 0 0 0;
}
.qksx-list-con:after {
  content: "";
  display: table;
  clear: both;
}
.qksx-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.xsqk-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qksx-list-cons {
  width: 100%;
  height: auto;
  float: left;
}
.qksx-list-cons-33 {
  width: 33.33%;
}
.qksx-list-cons:after {
  content: "";
  display: table;
  clear: both;
}

.qksx-list-cons-title {
  float: left;
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: #888;
}
.qksx-list-cons-nr {
  float: left;
  width: calc(100% - 100px);
  min-height: 30px;
}
.qksx-list-cons-nr:after {
  content: "";
  display: table;
  clear: both;
}
.qksx-list-cons-nrs {
  width: auto;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9ecff;
  background: #f7fbff;
  float: left;
  padding: 0 5px;
  margin: 0 5px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qksx-list-cons-nrs-tsys {
  border: 1px solid #f55b5b;
  background: #ffeded;
  color: #f00;
  cursor: pointer;
}
.xsqk {
  margin-top: 10px;
}
.xsqk-list {
  cursor: pointer;
}
.xsqk-list-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
}
.tips {
  color: #999;
  font-size: 12px;
  padding-top: 20px;
}
.xsfx-table-title {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}

.fwph-ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fwph-list {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  margin: 0 0 5px 0;
}
.fwph-list1 {
  width: 220px;
}
.fwph-list2 {
  width: 50px;
}
.fwph-list3 {
  width: 80px;
}
.tipsyuyan {
  display: inline-block;
  height: 19px;
}
#tgpgechaqrts {
  width: 100%;
  height: 400px;
}
.cxzs-display {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #67c23a;
  padding: 0 0 20px 0;
}
.cxzs-none {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #f00;
  padding: 0 0 20px 0;
}
.qkxq-tg {
  margin-top: 20px;
}
.tgxq-con {
  padding: 10px 20px;
}
.tgxq-list {
  font-size: 14px;
  margin-bottom: 10px;
}
.tgxq-list:after {
  content: "";
  display: table;
  clear: both;
}
.tgxq-list-title {
  float: left;
  width: 80px;
  height: auto;
  line-height: 24px;
  font-weight: bold;
}
.tgxq-list-con {
  float: left;
  width: calc(100% - 80px);
  height: auto;
  line-height: 24px;
  text-align: justify;
}
.qksx-list-con-zhfx {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qksx-list-con-zhfx-title {
  width: 25%;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.qksx-list-con-zhfx-title-wz {
  color: #f00;
  font-size: 20px;
  width: 50px;
  text-align: center;
}
.qksx-list-con-zhfx-title-tips {
  color: #999;
  font-weight: 500;
}
.qksx-list-con-zhfx-st {
  width: 75%;
}
.qksx-list-con-zhfx-st-list {
  width: 5%;
  height: 40px;
  line-height: 40px;
  float: left;
  color: #999;
  text-align: center;
}
.qksx-list-con-zhfx-st-list2 {
  width: 5%;
  height: 40px;
  line-height: 40px;
  float: left;
  color: #999;
  text-align: center;
}
.qksx-list-con-zhfx-st-list1 {
  width: 90%;
  float: left;
  margin-top: 17px;
}
</style>
